<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold;display:flex">
        <div style="color:#C8C8C8;cursor: pointer;" @click="goback">&lt;课程管理</div>
        <div>/课程包列表</div>
      </div>
      <div>
        <!-- <div> -->
        <el-button
          type="primary"
          style="height: 34px; line-height: 10px; margin-right: 45px"
          class="cinput"
          @click="addcontent"
        >+添加课程包</el-button>
        <!-- <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button label="2">视频</el-radio-button>
            <el-radio-button label="3">富文本</el-radio-button>
        </el-radio-group>-->
        <!-- </div> -->
        <div>
          <!-- <el-select
            v-model="value"
            placeholder="层级筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>-->
          <el-select v-model="brandId" placeholder="请选择"  class="cinput ac" style="margin: 0 23px"  @change="list">
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select
            v-model="leveId"
            placeholder="等级筛选"
            class="cinput ac"
            style="margin: 0 23px"
            @change="list"
          >
            <el-option
              v-for="item in levelLists"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入课程包名称"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt
                style="background: #f7f7f7"
              />
            </div>
          </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="date" align="center">
          <template slot-scope="scope">
            <div>
              <!-- <video :src="scope.row.videoUrl" class="avatar"  style="width:170px;height:108px">您的浏览器不支持视频播放</video> -->
              <img
                :src="'https://sihuankangyuan.cn' + scope.row.picUri"
                alt
                style="width:170px;height:108px;border-radius:4px"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center"></el-table-column>

        <el-table-column prop="address" align="center" width="180">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-link type="primary" :underline="false" @click="lookvideo(scope.row)">详情</el-link>
              <el-link type="primary" :underline="false" @click="dele(scope.row)">删除</el-link>
              <el-link type="primary" :underline="false" @click="lookcontent2(scope.row)">内容管理</el-link>
              <el-link
                type="primary"
                :underline="false"
                v-if="radio == '2'"
                @click="lookvideo(scope.row)"
              >查看视频</el-link>
              <el-link
                type="primary"
                :underline="false"
                v-if="radio == '3'"
                @click="lookcontent(scope.row)"
              >查看富文本</el-link>
              <!-- <el-link type="primary" :underline="false" @click='dele(scope.row)'>删除</el-link> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top:20px
        "
      >
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { contentpageList, contentsaveOrUpdate, courseInfoList, levelList, coursedelete } from '../../apis/index'
export default {
  data() {
    return {
      brandId: 1,
      brandList: [],
      levelLists: [],
      leveId: 1,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled: true,
      currentPage: 1,
      radio: "4",
      input: "",
      tableData: [

      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input = ''
        this.list()
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      this.radio = newdata
      this.pageIndex = 1
      this.list()
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
  },
  created() {
    this.brandList = this.$store.state.brandList
    this.leave().then(res => {
      this.list()
    })
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    dele(row) {
      console.log(row)
      const params = {
        courseId: row.id,
        //  contentIds:[row.id]
        //  id:row.id,
        //  isValidity:0,
        //  levelId:row.levelId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        coursedelete(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.list()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    goback() {
      this.$router.go(-1)
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({ roleId: 3 });
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    lookvideo(row) {
      this.$router.push({ path: '/courseAdd', query: row })
    },
    lookcontent2(row) {
      this.$router.push({ path: '/courseContent', query: row })
    },
    lookcontent(row) {
      this.$router.push({ path: '/contentAdd', query: row })
    },
    async list() {//课程包列表
      const params = {
        brandId:this.brandId,
        levelId: this.leveId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        // contentType:this.radio,
        searchStr: this.input
      }
      const { data } = await courseInfoList(params)
      if (data.code == 200) {
        this.tableData = data.data.records,
          this.total = Number(data.data.total)
      }
      console.log(data)
    },
    addcontent() {
      this.$router.push({ path: '/courseAdd' })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    search() {
      console.log("我是搜索");
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if (val.length > 0) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.list()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val
      this.list()
    },

    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
